import { PartnerCode } from '@/data/entity/types/PartnerCode';
import { partnerCodeSSR } from '@/data/server/partnerCodeData';
import dynamic from 'next/dynamic';
import Head from 'next/head';

const HeaderContainer = dynamic(
  () => import('@/presenter/container/HeaderContainer'),
  { ssr: false },
);

const HomeComponent = dynamic(
  () => import('@/presenter/component/home/HomeComponent'),
  { ssr: false },
);

/*********************************************************************************
 * 메인 페이지
 *********************************************************************************/
export default function Home() {
  return (
    <>
      <Head>
        <link rel="manifest" href="/favicon/manifest.json" />
      </Head>
      <HeaderContainer>
        <HomeComponent />
      </HeaderContainer>
    </>
  );
}

export async function getServerSideProps(context: any) {
  const result = await partnerCodeSSR(context);

  if (result === PartnerCode.SSRResult.FAIL) {
    return {
      redirect: {
        permanent: false,
        destination: `/`,
      },
    };
  }

  return {
    props: {},
  };
}
